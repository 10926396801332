export const TIMETABLE = [3, 7, 7, 7, 5, 5, 5, 3];
export const ROUNDS = 8;
export const MAX_INFO = 52;
export const ROUNDCONFIG = [
    { diceSummary: false, trainerSummary: false },
    { diceSummary: true, trainerSummary: true },
    { diceSummary: false, trainerSummary: false },
    { diceSummary: true, trainerSummary: true },
    { diceSummary: false, trainerSummary: false },
    { diceSummary: false, trainerSummary: false },
    { diceSummary: true, trainerSummary: false },
    { diceSummary: true, trainerSummary: false },
];
