import { get } from 'svelte/store';
import { stateStore } from './stateStore.js';
import { messages } from './layout.js';

export const popupContent = {};
export const actionLists = {
    observationStart: [],
    observationEnd: [],
    rest: [],
    training: [],
    lostOne: [],
    lostTwo: [],
};

const timeout = async (ms) => new Promise((res) => setTimeout(res, ms));

async function waitForPopup(message, playerList, lang) {
    stateStore.subscribe((store) =>
        console.log(store.isPopupClosed, store.isPopupVisible)
    );
    stateStore.update((store) => {
        return Object.assign(store, { isPopupClosed: false });
    });
    popupContent.header = messages[lang][message].header;
    popupContent.message = messages[lang][message].message;
    popupContent.class = messages[lang][message].class;
    playerList
        ? (popupContent.playerList = playerList)
        : (popupContent.playerList = []);
    stateStore.update((store) => {
        return Object.assign(store, { isPopupVisible: true });
    });
    let _isPopupClosed;
    stateStore.subscribe((store) => {
        _isPopupClosed = store.isPopupClosed;
    });
    while (_isPopupClosed === false) await timeout(50);
    const response = _isPopupClosed;
    console.log('resp', response);
    return response;
}

export async function showEarlyPopups(lang) {
    try {
        if (actionLists.observationEnd.length > 0)
            await waitForPopup(
                'observationEnded',
                actionLists.observationEnd,
                lang
            );
        if (actionLists.rest.length > 0)
            await waitForPopup('rested', actionLists.rest, lang);
        if (actionLists.training.length > 0)
            await waitForPopup('trained', actionLists.training, lang);
        if (actionLists.observationStart.length > 0)
            await waitForPopup('observed', actionLists.observationStart, lang);
    } catch (err) {
        console.log(err);
    }
}

export async function showMidPopups(lang) {
    try {
        const resp = await waitForPopup('askDices', '', lang);
        console.log('back from async!', resp);
        if (resp === 'notclosed') return 'abort';
    } catch (err) {
        console.log(err);
    }
}

export async function showLatePopups(lang) {
    try {
        if (actionLists.lostOne.length > 0)
            await waitForPopup('lostOne', actionLists.lostOne, lang);
        if (actionLists.lostTwo.length > 0)
            await waitForPopup('lostTwo', actionLists.lostTwo, lang);
        actionLists.observationEnd = [...actionLists.observationStart];
        for (let list in actionLists) {
            if (list !== 'observationEnd') actionLists[list] = [];
        }

        await waitForPopup('returnActions', '', lang);
    } catch (err) {
        console.log(err);
    }
}

export async function showOtherPopup(message, lang) {
    try {
        await waitForPopup(message, '', lang);
    } catch (err) {
        console.log(err);
    }
}

export async function showCancelPopup(lang) {
    try {
        const resp = await waitForPopup('incompleteInput', '', lang);
        console.log('back from async!', resp);
        if (resp === 'notclosed') return 'abort';
    } catch (err) {
        console.log(err);
    }
}
