<script>
    import Header from './Header.svelte';
    import Footer from './Footer.svelte';
    import Timer from './Timer.svelte';
    import PlayerBox from './PlayerBox.svelte';
    import Popup from './Popup.svelte';
    import { stateStore } from './stateStore.js';
    import { getGameData, saveGame } from './licenseHandler';
    import { getGridClass } from './layout.js';
    import { TIMETABLE, ROUNDS, ROUNDCONFIG } from './gameConfig.js';
    import { LABELS } from './layout.js';
    import {
        playersCleanup,
        calcRoundResults,
        calcTutorialResults,
        playersCleanupTutorial,
        cleanTutorial,
        calculateFinalResults,
    } from './gameLogic.js';
    import {
        showLatePopups,
        showMidPopups,
        showEarlyPopups,
        showOtherPopup,
    } from './popupsLogic.js';
    import { fillTutorialDecisions } from './tutorialHandler.js';
    import Swal from 'sweetalert2';
    export let gameData = {};
    export let players = [];
    let timer = 0;
    export let tools = {};
    export let playersNumber = 8;
    export let timerState = false;
    const game = checkGame();

    let mode = 'tutorial';
    //const mode = game === 'tutorial' ? 'tutorial' : 'game';
    const lang = game.lang;

    let validity = game === 'tutorial' ? 'valid' : 'waiting';
    let numberPlayersGridClass = 'temp';

    function checkGame() {
        let connectionData = new URL(window.location);
        const urlData = {};
        urlData.mode = connectionData.searchParams.get('mode');
        urlData.gameCode = connectionData.searchParams.get('code');
        urlData.lang = connectionData.searchParams.get('lang');
        return urlData;
    }

    async function gameSetup() {
        const gameFromServer = await getGameData(game.mode, game.gameCode);
        gameData = gameFromServer.gameData;
        console.log(gameFromServer);
        validity = gameFromServer.validity;
        playersNumber = gameFromServer.gameData.playersCount;
        players = gameFromServer.gameData.playersArray;
        console.log(playersNumber, players);
        numberPlayersGridClass = getGridClass(playersNumber);
        console.log(numberPlayersGridClass);
        if (!gameData.isGameEnd) gameData.phase = 'decisions';
        if (gameData.mode) mode = gameData.mode;
    }

    async function tutorialNextPhase() {
        if (gameData.phase === 'decisions') {
            console.log('starting!');
            calcTutorialResults(players, gameData.playersCount, gameData.round);
            await showEarlyPopups(lang);
            gameData.phase = 'roundSummary';
            console.log('nextphase', gameData.phase);
        } else if (gameData.phase === 'roundSummary') {
            console.log('summary started');
            const isEveryoneReady = await showMidPopups(lang);
            if (isEveryoneReady === 'abort') return;
            playersCleanupTutorial(players, lang, gameData.round);
            await showLatePopups(lang);
            gameData.round++;
            gameData.phase = 'decisions';
        }
        if (gameData.round > 2) {
            cleanTutorial(players);
            gameData.round = 1;
            mode = 'game';
            gameData.mode = 'game';
            tools.startTimer(TIMETABLE[gameData.round - 1]);
        }
    }

    async function nextPhase() {
        if (gameData.phase === 'decisions') {
            let isEveryOneReady = true;
            players.forEach((player) => {
                if (player.decisionsCounter !== 3) {
                    isEveryOneReady = false;
                }
            });
            if (!isEveryOneReady) {
                showOtherPopup('notEveryOneReady', lang);
                return;
            }
            console.log('starting!');
            calcRoundResults(players, gameData.playersCount);
            showEarlyPopups(lang);
            gameData.phase = 'roundSummary';
            return;
        } else if (gameData.phase === 'roundSummary') {
            console.log('summary started');
            const isEveryoneReady = await showMidPopups(lang);
            if (isEveryoneReady === 'abort') return;
            playersCleanup(players, lang);
            await showLatePopups(lang);

            if (ROUNDCONFIG[gameData.round - 1].trainerSummary) {
                await showOtherPopup('trainerSummary', lang);
            }
            if (ROUNDCONFIG[gameData.round - 1].diceSummary) {
                gameData.phase = 'gameSummary';
                return;
            }
        }

        gameData.phase = 'decisions';
        gameData.round++;

        if (gameData.round > ROUNDS) {
            calculateFinalResults(players, gameData.playersCount);
            await showOtherPopup('endGame', lang);
            gameData.isGameEnd = true;
            gameData.phase = 'endResults';
        } else {
            tools.startTimer(TIMETABLE[gameData.round - 1]);
        }
        saveGame(gameData);
    }
    function autoFillTutorial() {
        players.forEach((player) => {
            fillTutorialDecisions(player, gameData.round, lang);
        });
        players = players;
    }
    $: console.log(lang);
</script>

<svelte:head>
    <title
        >{lang
            ? LABELS[lang].title_header + ' | ' + LABELS[lang].title_header2
            : '...'}</title
    >
</svelte:head>
{#await gameSetup()}
    <p>waiting</p>
{:then _}
    <Header
        {lang}
        {mode}
        bind:timerState
        bind:gameData
        on:start_timer={(event) => {
            tools.startTimer(event.detail);
        }}
        on:next_phase={nextPhase}
        on:tutorial_next_phase={tutorialNextPhase}
        on:auto_fill_tutorial={autoFillTutorial}
        ><Timer bind:tools bind:timerState {mode} />
    </Header>
    <div class="main" id="main">
        <div class={numberPlayersGridClass}>
            {#each players as player}
                <PlayerBox
                    phase={gameData.phase}
                    bind:player
                    {lang}
                    {mode}
                    round={gameData.round}
                />
            {/each}
        </div>
    </div>
    <Footer />
    {#if $stateStore.isPopupVisible}
        <Popup {lang} />
    {/if}
    <!-- <span>{players[0].name + gameData.playersArray[0].name}</span> -->
{/await}
