import axios from 'axios';

export const getGameData = async (mode, gameCode) => {
    const url = `/trener/checkgame`;
    const data = {
        mode,
        gameCode,
    };

    try {
        // console.log(data);
        const res = await axios({
            method: 'POST',
            url,
            data,
        });
        if (res.status === 200) {
            //console.log(' incoming data', res.data);
            //console.log(res.data.data);
            const gameFromServer = {
                validity: 'valid',
                gameData: res.data.data,
            };
            return gameFromServer;
        } else if (res.status === 201) {
            return 'wronggame';
        } else if (res.status === 202) {
            return 'wrongcode';
        } else if (res.status === 203) {
            return 'expired';
        } else {
            console.log('unknown status');
        }
    } catch (err) {
        console.log(err);
    }
};

export async function saveGame(gameData) {
    const url = `/trener/savegame`;
    const data = {
        gameCode: gameData.code,
        gameData,
    };
    console.log('lh', data);
    try {
        // console.log(data);
        const res = await axios({
            method: 'POST',
            url,
            data,
        });
        if (res.status === 200) console.log('game saved!');
    } catch (err) {
        console.log(err);
    }
}
