<script>
    export let player;
    if (!player.analysisResults) player.analysisResults = [];
    const isDifferent1 = checkDifference(player.analysisResults[0]);
    const isDifferent2 = checkDifference(player.analysisResults[1]);
    import { LABELS } from './layout.js';
    export let lang = '';

    function checkDifference(obj) {
        if (!obj) return 'no action!';
        for (let pointType in obj.effect) {
            if (obj.effect[pointType] !== 0) return true;
        }
        return false;
    }
</script>

<img
    class="player-box__img--smaller"
    src="img/{player.playerNumber}.png"
    alt="agent-{player.playerNumber}-badge"
/>
<div class="player-box__name--filled">
    <h2 class="heading-secondary name-input">{player.name}</h2>
</div>
<div class="player-box__analysis">
    <div class="analysis-box">
        <div class="analysis-box__left">
            <span class="analysis__text--smaller">{LABELS[lang].analysis}</span>
            <span class="analysis__text">{LABELS[lang].results}</span>
        </div>
        <div class="analysis-box__right" />
    </div>

    <div class="analysis-effect">
        <div class="analysis-effect-left">
            <div
                class={isDifferent1 === 'no action!'
                    ? 'analysis-effect-top analysis-effect-top--other'
                    : `analysis-effect-top analysis-effect-top--${player.analysisResults[0].operation}`}
            >
                {#if isDifferent1 !== 'no action!'}
                    <span class="decision__text"
                        >{LABELS[lang][player.analysisResults[0].operation]}: {player
                            .analysisResults[0].place}
                    </span>
                {/if}
            </div>
            <div
                class={isDifferent2 === 'no action!'
                    ? 'analysis-effect-bottom analysis-effect-bottom--other'
                    : `analysis-effect-bottom analysis-effect-bottom--${player.analysisResults[0].operation}`}
            >
                {#if !isDifferent1}
                    <span class="decision__text"
                        >{LABELS[lang].analysis_same_as_board}</span
                    >
                {:else if isDifferent1 !== 'no action!'}
                    <div class="analysis__dices">
                        {#if player.analysisResults[0].effect.dragons}
                            <div class="dice">
                                <img
                                    class="dice__img"
                                    src="../img/smok.png"
                                    alt=""
                                />
                                <span class="dice__text"
                                    >{player.analysisResults[0].effect
                                        .dragons}</span
                                >
                            </div>
                        {/if}
                        {#if player.analysisResults[0].effect.wasps}
                            <div class="dice">
                                <img
                                    class="dice__img"
                                    src="../img/osy.png"
                                    alt=""
                                />
                                <span class="dice__text"
                                    >{player.analysisResults[0].effect
                                        .wasps}</span
                                >
                            </div>
                        {/if}
                        {#if player.analysisResults[0].effect.cobras}
                            <div class="dice">
                                <img
                                    class="dice__img"
                                    src="../img/kobry.png"
                                    alt=""
                                />
                                <span class="dice__text"
                                    >{player.analysisResults[0].effect
                                        .cobras}</span
                                >
                            </div>
                        {/if}
                        {#if player.analysisResults[0].effect.sharks}
                            <div class="dice">
                                <img
                                    class="dice__img"
                                    src="../img/rekiny.png"
                                    alt=""
                                />
                                <span class="dice__text"
                                    >{player.analysisResults[0].effect
                                        .sharks}</span
                                >
                            </div>
                        {/if}
                    </div>
                {:else}
                    <span class="decision__text"
                        >{LABELS[lang].analysis_results_no_operation}</span
                    >
                {/if}
            </div>
        </div>
        <div class="analysis-effect-right">
            <div
                class={isDifferent2 === 'no action!'
                    ? 'analysis-effect-top analysis-effect-top--other'
                    : `analysis-effect-top analysis-effect-top--${player.analysisResults[1].operation}`}
            >
                {#if isDifferent2 !== 'no action!'}
                    <span class="decision__text"
                        >{LABELS[lang][player.analysisResults[1].operation]}: {player
                            .analysisResults[1].place}</span
                    >
                {/if}
            </div>
            <div
                class={isDifferent2 === 'no action!'
                    ? 'analysis-effect-bottom analysis-effect-bottom--other'
                    : `analysis-effect-bottom analysis-effect-bottom--${player.analysisResults[1].operation}`}
            >
                {#if !isDifferent2}
                    <span class="decision__text"
                        >{LABELS[lang].analysis_same_as_board}</span
                    >
                {:else if isDifferent2 !== 'no action!'}
                    <div class="analysis__dices">
                        {#if player.analysisResults[1].effect.dragons}
                            <div class="dice">
                                <img
                                    class="dice__img"
                                    src="../img/smok.png"
                                    alt=""
                                />
                                <span class="dice__text"
                                    >{player.analysisResults[1].effect
                                        .dragons}</span
                                >
                            </div>
                        {/if}
                        {#if player.analysisResults[1].effect.wasps}
                            <div class="dice">
                                <img
                                    class="dice__img"
                                    src="../img/osy.png"
                                    alt=""
                                />
                                <span class="dice__text"
                                    >{player.analysisResults[1].effect
                                        .wasps}</span
                                >
                            </div>
                        {/if}
                        {#if player.analysisResults[1].effect.cobras}
                            <div class="dice">
                                <img
                                    class="dice__img"
                                    src="../img/kobry.png"
                                    alt=""
                                />
                                <span class="dice__text"
                                    >{player.analysisResults[1].effect
                                        .cobras}</span
                                >
                            </div>
                        {/if}
                        {#if player.analysisResults[1].effect.sharks}
                            <div class="dice">
                                <img
                                    class="dice__img"
                                    src="../img/rekiny.png"
                                    alt=""
                                />
                                <span class="dice__text"
                                    >{player.analysisResults[1].effect
                                        .sharks}</span
                                >
                            </div>
                        {/if}
                    </div>
                {:else}
                    <span class="decision__text"
                        >{LABELS[lang].analysis_results_no_operation}</span
                    >
                {/if}
            </div>
        </div>
    </div>
</div>
