<script>
    import { messages } from './layout.js';
    import { stateStore } from './stateStore.js';
    import { popupContent } from './popupsLogic.js';
    import { LABELS, BACKGROUND_IMAGES } from './layout.js';
    export let lang = '';
    let nextBtnBgImage = `/img/${lang}/next.png`;

    // export let isPopupClosed;
    function closePopup(message) {
        stateStore.update((store) => {
            return Object.assign(store, { isPopupClosed: message });
        });
        // console.log('inside', stateStore.isPopupClosed);
        stateStore.update((store) => {
            return Object.assign(store, { isPopupVisible: false });
        });
    }
    function hover() {
        nextBtnBgImage = `/img/${lang}/next-hover.png`;
    }
    function unhover() {
        nextBtnBgImage = `/img/${lang}/next.png`;
    }

    //  $: console.log('outside', stateStore.isPopupClosed);
</script>

<div class="popup" id="popup-information">
    <div class="popup__content {popupContent.class}">
        <h1 class="heading-primary">
            <span class="heading-primary--main">{popupContent.header}</span>
        </h1>
        <button
            class="btn-information"
            style="background-image:url({nextBtnBgImage})"
            on:click={() => closePopup('closed')}
            on:mouseenter={hover}
            on:mouseleave={unhover}
        />

        <div class="information-content">
            {#if popupContent.playerList && popupContent.playerList.length > 0}
                <p class="information__text">{LABELS[lang].player}</p>
                <p />
                <div class="information__players-list">
                    {#each popupContent.playerList as player}
                        <p class="information__text information__text--float">
                            {player}
                        </p>
                        <p />{/each}
                </div>
            {/if}
            <p class="information__text">{popupContent.message}</p>
            {#if popupContent.message === messages[lang].askDices.message}
                <button
                    class="btn btn--change"
                    on:click={() => closePopup('notclosed')}
                    ><span class="btn__text"
                        >{@html LABELS[lang].show_dices}</span
                    ></button
                >
            {/if}
            {#if popupContent.message === messages[lang].incompleteInput.message}
                <button
                    class="btn btn--change"
                    on:click={() => closePopup('notclosed')}
                    ><span class="btn__text"
                        >{@html LABELS[lang].return_to_dialog}</span
                    ></button
                >
            {/if}
        </div>
    </div>
</div>
