<script>
    export let player;
</script>

<img
    class="player-box__img--smaller"
    src="img/{player.playerNumber}.png"
    alt="agent-{player.playerNumber}-badge"
/>
<div class="player-box__name--filled">
    <h2 class="heading-secondary name-input">{player.name}</h2>
</div>
<div class="player-box__decision">
    {#each player.currentDecisions as decision}
        <div class="decision-box">
            <div
                class="decision-box__left
                            decision-box__left--{decision.action}"
            >
                <span class="decision__text"
                    >{decision.action === 'observation' ||
                    decision.action === 'rest' ||
                    decision.action === 'analysis'
                        ? decision.actionLabel
                        : `${decision.actionLabel}:`}</span
                >
            </div>

            <div
                class="decision-box__right
                            decision-box__right--{decision.action}"
            >
                <span class="decision__text"
                    >{decision.placeLabel ? `${decision.placeLabel}` : ''}</span
                >
            </div>
        </div>
    {/each}
</div>
