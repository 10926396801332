<script>
    export let player;
    export let phase;
    import { LABELS } from './layout.js';
    export let lang = '';
    const LAYOUT = [
        {
            nameLine1: LABELS[lang].round_summary_nameLine1_cobras,
            nameLine2: LABELS[lang].round_summary_nameLine2_cobras,
            img: 'kobry',
            points: 'cobras',
        },
        {
            nameLine1: LABELS[lang].round_summary_nameLine1_sharks,
            nameLine2: LABELS[lang].round_summary_nameLine2_sharks,
            img: 'rekiny',
            points: 'sharks',
        },
        {
            nameLine1: LABELS[lang].round_summary_nameLine1_wasps,
            nameLine2: LABELS[lang].round_summary_nameLine2_wasps,
            img: 'osy',
            points: 'wasps',
        },
        {
            nameLine1: LABELS[lang].round_summary_nameLine1_dragons,
            nameLine2: LABELS[lang].round_summary_nameLine2_dragons,
            img: 'smok',
            points: 'dragons',
        },
        {
            nameLine1: LABELS[lang].round_summary_nameLine1_irritation,
            nameLine2: LABELS[lang].round_summary_nameLine2_irritation,
            img: 'irytacja',
            points: 'irritation',
        },
    ];
</script>

<img
    class="player-box__img--smaller"
    src="img/{player.playerNumber}.png"
    alt="agent-{player.playerNumber}-badge"
/>
<div class="player-box__name--filled">
    <h2 class="heading-secondary name-input">{player.name}</h2>
</div>
<div class="player-box__dices">
    {#each LAYOUT as pointType}
        <div class="dice-effect">
            <div class="single-dice">
                <img src="../img/frame.png" alt="" class="single-dice__frame" />
                <img
                    class="single-dice__img"
                    src="../img/{pointType.img}.png"
                    alt=""
                />
                <span class="dice__text--single"
                    >{#if phase === 'roundSummary'}
                        {player.currentRoundScore[pointType.points]}
                    {:else}
                        {pointType.points === 'irritation'
                            ? player.irritation
                            : player.informations[pointType.points]}
                    {/if}</span
                >
            </div>
            <span class="dice-effect__label">{pointType.nameLine1}</span>
            <span class="dice-effect__label">{pointType.nameLine2}</span>
        </div>
    {/each}
</div>
