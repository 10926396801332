<script>
    export let isInputVisible = true;
    export let player;
    import { LABELS } from './layout.js';
    export let lang = '';
    import { showCancelPopup } from './popupsLogic.js';
    function toggleSelection(button) {
        console.log('click!');
        if (!button.selected) {
            button.selected = true;
            player.decisionsCounter++;
        } else {
            button.selected = false;
            player.decisionsCounter--;
        }
    }
    async function closeDecisions() {
        player.isAnalysing = false;
        if (player.decisionsCounter !== 3) {
            const isSure = await showCancelPopup(lang);
            if (isSure === 'abort') return;
        }
        player.currentDecisions = [];
        player.trainings.forEach((el) => {
            if (el.selected)
                player.currentDecisions.push({
                    action: 'training',
                    place: el.name,
                    actionLabel: LABELS[lang].training,
                    placeLabel: el.label[lang],
                });
        });
        player.places.forEach((el) => {
            for (let prop in el) {
                if (el[prop].selected)
                    player.currentDecisions.push({
                        place: el.placeName[lang],
                        placeLabel: el.placeName[lang],
                        action: prop,
                        actionLabel: LABELS[lang][prop],
                    });
            }
        });
        for (let prop in player.otherActions) {
            if (player.otherActions[prop]) {
                let action;
                if (prop === 'rest1' || prop === 'rest2') {
                    action = 'rest';
                } else action = prop;
                const actionLabel = LABELS[lang][action];

                player.currentDecisions.push({ actionLabel, action });
            }
        }
        console.log(player.currentDecisions);
        isInputVisible = false;
    }

    $: console.log('cnt', player.decisionsCounter, player);
</script>

<div class="popup" id="popup-decision">
    <div class="popup__content">
        <div class="header__popup">
            <h1 class="heading-primary">
                <span class="heading-primary--main"
                    >{LABELS[lang].decision_header} {player.name}</span
                >
            </h1>
        </div>

        <div class="popup__actions popup-grid">
            <div class="action action--raid-{lang}">
                <div class="action__header-box action--raid-header">
                    <h1 class="heading-primary">
                        <span class="heading-primary--raid-{lang}"
                            >{LABELS[lang].raid}</span
                        >
                    </h1>
                </div>

                <div class="action__button-box action__button-box--raid">
                    {#each player.places as place}
                        <button
                            class="btn-popup btn-popup--raid"
                            class:u-padding-button={place.placeName[lang]
                                .length > 10}
                            class:btn-popup-clicked={place.raid.selected}
                            class:btn-popup-clicked--raid={place.raid.selected}
                            on:click={() => {
                                toggleSelection(place.raid);
                            }}
                            disabled={!place.raid.selected &&
                                player.decisionsCounter === 3}
                        >
                            <span
                                class="btn-popup-text "
                                class:btn-popup-text--smaller={place.placeName[
                                    lang
                                ].length > 10}>{place.placeName[lang]}</span
                            >
                        </button>
                    {/each}
                </div>
            </div>

            <div class="action action--provocation-{lang}">
                <div class="action__header-box action--provocation-header">
                    <h1 class="heading-primary">
                        <span class="heading-primary--provocation-{lang}"
                            >{LABELS[lang].provocation}</span
                        >
                    </h1>
                </div>

                <div class="action__button-box action__button-box--provocation">
                    {#each player.places as place}
                        <button
                            class="btn-popup btn-popup--provocation"
                            class:u-padding-button={place.placeName[lang]
                                .length > 10}
                            class:btn-popup-clicked={place.provocation.selected}
                            class:btn-popup-clicked--provocation={place
                                .provocation.selected}
                            on:click={() => {
                                toggleSelection(place.provocation);
                            }}
                            disabled={!place.provocation.selected &&
                                player.decisionsCounter === 3}
                        >
                            <span
                                class="btn-popup-text"
                                class:btn-popup-text--smaller={place.placeName[
                                    lang
                                ].length > 10}>{place.placeName[lang]}</span
                            >
                        </button>
                    {/each}
                </div>
            </div>
            <div class="action action--recon-{lang}">
                <div class="action__header-box action--recon-header">
                    <h1 class="heading-primary">
                        <span class="heading-primary--recon-{lang}"
                            >{LABELS[lang].recon}</span
                        >
                    </h1>
                </div>

                <div class="action__button-box action__button-box--recon">
                    {#each player.places as place}
                        <button
                            class="btn-popup btn-popup--recon"
                            class:u-padding-button={place.placeName[lang]
                                .length > 10}
                            class:btn-popup-clicked={place.recon.selected}
                            class:btn-popup-clicked--recon={place.recon
                                .selected}
                            on:click={() => {
                                toggleSelection(place.recon);
                            }}
                            disabled={!place.recon.selected &&
                                player.decisionsCounter === 3}
                        >
                            <span
                                class="btn-popup-text"
                                class:btn-popup-text--smaller={place.placeName[
                                    lang
                                ].length > 10}>{place.placeName[lang]}</span
                            >
                        </button>
                    {/each}
                </div>
            </div>

            <div class="action action--wiretapping-{lang}">
                <div class="action__header-box  action--wiretapping-header">
                    <h1 class="heading-primary">
                        <span class="heading-primary--wiretapping-{lang}"
                            >{LABELS[lang].wiretapping}</span
                        >
                    </h1>
                </div>
                <div class="action__button-box action__button-box--wiretapping">
                    {#each player.places as place}
                        <button
                            class="btn-popup btn-popup--wiretapping"
                            class:u-padding-button={place.placeName[lang]
                                .length > 10}
                            class:btn-popup-clicked={place.wiretapping.selected}
                            class:btn-popup-clicked--wiretapping={place
                                .wiretapping.selected}
                            on:click={() => {
                                toggleSelection(place.wiretapping);
                            }}
                            disabled={!place.wiretapping.selected &&
                                player.decisionsCounter === 3}
                        >
                            <span
                                class="btn-popup-text"
                                class:btn-popup-text--smaller={place.placeName[
                                    lang
                                ].length > 10}>{place.placeName[lang]}</span
                            >
                        </button>
                    {/each}
                </div>
            </div>

            <div class="action action--training-{lang}">
                <h1 class="heading-primary action--training-header">
                    <span class="heading-primary--training-{lang}"
                        >{LABELS[lang].training}</span
                    >
                </h1>
                <div class="action__button-box action__button-box--training">
                    {#each player.trainings as training}
                        <button
                            class="btn-popup btn-popup--training"
                            class:u-padding-button={training.label[lang]
                                .length > 10}
                            class:btn-popup--learned={training.learned}
                            class:btn-popup-clicked={training.selected}
                            class:btn-popup-clicked--training={training.selected}
                            on:click={() => {
                                console.log(training);
                                toggleSelection(training);
                            }}
                            disabled={training.learned ||
                                (!training.selected &&
                                    player.decisionsCounter === 3)}
                        >
                            <span
                                class="btn-popup-text"
                                class:btn-popup-text--smaller={training.label[
                                    lang
                                ].length > 10}>{training.label[lang]}</span
                            >
                        </button>
                    {/each}
                </div>
            </div>
            <div class="action action--other">
                <h1 class="heading-primary">
                    <span class="heading-primary--rest-{lang}"
                        >{LABELS[lang].rest}</span
                    >
                    <div class="action__form">
                        <div class="action__form--group">
                            <input
                                type="checkbox"
                                class="action__input"
                                name="rest"
                                id="rest-check-1"
                                bind:checked={player.otherActions.rest1}
                                disabled={!player.otherActions.rest1 &&
                                    player.decisionsCounter === 3}
                                on:change={() => {
                                    player.otherActions.rest1
                                        ? player.decisionsCounter++
                                        : player.decisionsCounter--;
                                }}
                            />
                            <label
                                class="action__input-label"
                                for="rest-check-1"
                            >
                                <span class="action__input-checkbox" />
                            </label>
                        </div>
                        <div class="action__form--group">
                            <input
                                type="checkbox"
                                class="action__input"
                                name="rest"
                                id="rest-check-2"
                                bind:checked={player.otherActions.rest2}
                                disabled={!player.otherActions.rest2 &&
                                    player.decisionsCounter === 3}
                                on:change={() => {
                                    player.otherActions.rest2
                                        ? player.decisionsCounter++
                                        : player.decisionsCounter--;
                                }}
                            />
                            <label
                                class="action__input-label"
                                for="rest-check-2"
                            >
                                <span class="action__input-checkbox" />
                            </label>
                        </div>
                    </div>
                </h1>

                <h1 class="heading-primary">
                    <span class="heading-primary--observation-{lang}"
                        >{LABELS[lang].observation}</span
                    >
                    <div class="action__form">
                        <input
                            type="checkbox"
                            class="action__input"
                            name="observation"
                            id="observation"
                            bind:checked={player.otherActions.observation}
                            disabled={!player.otherActions.observation &&
                                player.decisionsCounter === 3}
                            on:change={() => {
                                player.otherActions.observation
                                    ? player.decisionsCounter++
                                    : player.decisionsCounter--;
                            }}
                        />
                        <label class="action__input-label" for="observation">
                            <span class="action__input-checkbox" />
                        </label>
                    </div>
                </h1>
                <h1 class="heading-primary">
                    <span class="heading-primary--analysis-{lang}"
                        >{LABELS[lang].analysis}</span
                    >
                    <div class="action__form">
                        <input
                            type="checkbox"
                            class="action__input"
                            name="analysis"
                            id="analysis"
                            bind:checked={player.otherActions.analysis}
                            disabled={!player.otherActions.analysis &&
                                player.decisionsCounter === 3}
                            on:change={() => {
                                player.otherActions.analysis
                                    ? player.decisionsCounter++
                                    : player.decisionsCounter--;
                            }}
                        />
                        <label class="action__input-label" for="analysis">
                            <span class="action__input-checkbox" />
                        </label>
                    </div>
                </h1>
            </div>
        </div>
    </div>
    <button class="btn btn--accept" on:click={closeDecisions}
        ><span class="btn__text">{LABELS[lang].decision_button_accept}</span
        >&nbsp;</button
    >
</div>
