import { LABELS } from './layout.js';

export async function fillTutorialDecisions(player, round, lang) {
    for (let operation in player.tutorial[0]) {
        player.tutorial[0][operation].selected = false;
    }

    for (let action in player.otherActions) {
        player.otherActions[action] = false;
    }

    if (round === 1) {
        player.tutorial[0].recon.selected = true;
        player.tutorial[0].provocation.selected = true;
        player.otherActions.analysis = true;
        player.decisionsCounter = 3;
    } else if (round === 2) {
        player.tutorial[0].recon.selected = true;
        player.otherActions.rest1 = true;
        player.otherActions.observation = true;
        player.decisionsCounter = 3;
    }
    closeDecisions(player, lang);
}

function closeDecisions(player, lang) {
    player.isAnalysing = false;
    if (player.decisionsCounter !== 3) return;
    player.currentDecisions = [];

    player.tutorial.forEach((el) => {
        for (let prop in el) {
            if (el[prop].selected)
                player.currentDecisions.push({
                    place: el.placeName[lang],
                    placeLabel: el.placeName[lang],
                    action: prop,
                    actionLabel: LABELS[lang][prop],
                });
        }
    });
    for (let prop in player.otherActions) {
        if (player.otherActions[prop]) {
            let action;
            if (prop === 'rest1' || prop === 'rest2') {
                action = 'rest';
            } else action = prop;
            const actionLabel = LABELS[lang][action];

            player.currentDecisions.push({ actionLabel, action });
        }
    }
    player.decisionsCounter = 3;
}
