<script>
    export let player = '';
    export let lang = '';
    import { LABELS } from './layout';
</script>

<img
    class="player-box__img--smaller"
    src="img/{player.playerNumber}.png"
    alt="agent-{player.playerNumber}-badge"
/>
<div class="player-box__name--filled">
    <h2 class="heading-secondary name-input">{player.name}</h2>
</div>
<div class="player-box__results">
    <div class="results-box">
        <h1 class="heading-primary">
            <span class="heading-primary--results"
                >{LABELS[lang].partners_points}</span
            >
        </h1>
        <div class="points-box">
            <div class="arrow arrow--left" />
            <div class="arrow arrow--left" />
            <div class="arrow arrow--left" />
            <div class="points">
                <img class="points__img" src="../img/irytacja.png" alt="" />
                <span class="points__text"
                    >{player.finalScore.partnersBoard}</span
                >
            </div>
        </div>
    </div>
    <div class="results-box">
        <h1 class="heading-primary">
            <span class="heading-primary--results"
                >{LABELS[lang].your_points}</span
            >
        </h1>
        <div class="points-box">
            <div class="points">
                <img class="points__img" src="../img/irytacja.png" alt="" />
                <span class="points__text">{player.finalScore.ownBoard}</span>
            </div>
        </div>
    </div>
    <div class="results-box results-box--final">
        <h1 class="heading-primary">
            <span class="heading-primary--results"
                >{LABELS[lang].total_points}</span
            >
        </h1>
        <div class="points-box">
            <div class="points--final">
                <img class="points__img" src="../img/irytacja.png" alt="" />
                <span class="points__text">
                    <span class="points__text"
                        >{player.finalScore.totalScore}</span
                    >
                </span>
            </div>
        </div>
    </div>
</div>
