<script>
    export let gameData;
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    import { LABELS, BACKGROUND_IMAGES } from './layout.js';
    import { ROUNDS } from './gameConfig.js';
    export let mode = '';
    export let lang = '';
    let nextPhaseBgImage = '';
    let tildeBgImage = '/img/tylda.png';
    export let timerState = false;
    function nextPhase() {
        if (mode === 'tutorial') {
            dispatch('tutorial_next_phase');
        } else dispatch('next_phase');
    }
    // $: if (gameData.phase === 'decisions') {
    //     nextPhaseBgImage = `/img/${lang}/${BACKGROUND_IMAGES[gameData.phase]}.png`;
    // } else if (
    //     gameData.phase === 'roundSummary' ||
    //     gameData.phase === 'gameSummary'
    // ) {
    //     nextPhaseBgImage = `/img/${lang}/${BACKGROUND_IMAGES[gameData.phase]}.png`;
    // }
    $: nextPhaseBgImage = `/img/${lang}/${
        BACKGROUND_IMAGES[gameData.phase]
    }.png`;

    function hover() {
        nextPhaseBgImage = `/img/${lang}/${
            BACKGROUND_IMAGES[gameData.phase]
        }-hover.png`;
    }
    function unhover() {
        nextPhaseBgImage = `/img/${lang}/${
            BACKGROUND_IMAGES[gameData.phase]
        }.png`;
    }
    function tildeHover() {
        tildeBgImage = `/img/tylda-hover.png`;
    }
    function tildeUnhover() {
        tildeBgImage = `/img/tylda.png`;
    }
    $: console.log('header ', gameData.phase);
</script>

<div class="header">
    <div class="header__text-box">
        <h1 class="heading-primary">
            <span class="heading-primary--main">
                {#if mode === 'tutorial'}
                    {`Tutorial: ${LABELS[lang].header_round + gameData.round}`}
                {:else if gameData.phase === 'endResults'}
                    {LABELS[lang].header_results_endgame}
                {:else if gameData.phase !== 'endResults'}
                    {LABELS[lang].header_round + gameData.round}
                {/if}
            </span>
        </h1>

        <br />
        <h1 class="heading-primary">
            <span class="heading-primary--smaller"
                >{#if gameData.phase === 'roundSummary'}
                    {LABELS[lang].header_round_results}
                {:else if gameData.phase === 'gameSummary'}
                    {LABELS[lang].header_results_summary}
                {/if}
            </span>
        </h1>
    </div>
    <div class="header__button-box">
        {#if gameData.phase !== 'endResults'}<button
                class="btn-header btn-header--function"
                style="background-image:url({nextPhaseBgImage})"
                on:click={nextPhase}
                on:mouseenter={hover}
                on:mouseleave={unhover}
            />{/if}
        {#if mode === 'tutorial'}
            <button
                class="btn-header btn-header--tylda"
                style="background-image:url({tildeBgImage})"
                on:click={() => {
                    dispatch('auto_fill_tutorial');
                }}
                on:mouseenter={tildeHover}
                on:mouseleave={tildeUnhover}
            />
        {/if}
    </div>

    <div class="header__timer">
        <div
            class="timer__box"
            class:timer__box-end={!timerState}
            class:u-hidden={mode === 'tutorial'}
        >
            {#if timerState}
                <p class="timer__text">{LABELS[lang].timer_label}</p>
            {/if}
            <slot />
        </div>
    </div>
</div>
