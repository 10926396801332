<script>
    const minutesToSeconds = (minutes) => minutes * 60;
    const secondsToMinutes = (seconds) => Math.floor(seconds / 60);
    const padWithZeroes = (number) => number.toString().padStart(2, '0');
    let interval;
    export let mode = '';
    let timer = minutesToSeconds(0);
    export let timerState = true;

    function formatTime(timeInSeconds) {
        const minutes = secondsToMinutes(timeInSeconds);
        const remainingSeconds = timeInSeconds % 60;
        return `${padWithZeroes(minutes)}:${padWithZeroes(remainingSeconds)}`;
    }

    export const tools = {
        startTimer(duration, resume = false) {
            if (interval) {
                clearInterval(interval);
            }
            timerState = true;
            !resume ? (timer = minutesToSeconds(duration)) : (timer = duration);
            interval = setInterval(() => {
                if (timer === 0) {
                    timerState = false;
                    clearInterval(interval);
                    return;
                }
                timer -= 1;
            }, 1000);
        },
    };
</script>

{#if mode === 'game'}
    {#if timerState}
        <p class="timer__count" id="timer"><time>{formatTime(timer)}</time></p>
    {:else}
        <p>Koniec czasu</p>
    {/if}
{:else}
    <p>Placeholder</p>
{/if}
